import React from "react";
import styled from "styled-components";
import { track } from "../../../../lib";
import { Flex, Box } from "../../../ui";
import { EVENT } from "../../../utils/constants";
import {
  discColorMapping,
  discIconMapping,
  discIdToLetterMapping
} from "../../TeamDashboard/utils";
import ToggleButtonComponent from "../../ToggleButton/ToggleButtonComponent";
import ToggleButtonDetails from "../../ToggleButton/ToggleButtonDetails";
import { TeamToggleButtonDetails } from "./TeamToggleButtonDetails";
import { useContentManager } from "@/components/composite/ContentManager";

const TraitBox = styled(Box)`
  margin-top: 8px;

  &:first-child {
    margin-top: 0;
  }
`;

const letterIndices = {
  D: 0,
  I: 1,
  S: 2,
  C: 3,
};

function DiscTraits({
  traits = [],
  trackingData = undefined,
  disabled = false,
  children = () => undefined,
}) {
  const { wrapContent } = useContentManager();

  const [state, setState] = React.useState({
    toggledLetter: undefined,
  });

  const handleClick = letter => () => {
    setState({
      toggledLetter: state.toggledLetter === letter
        ? undefined
        : letter,
    });
  }

  const handleClose = (eventName, properties, trackingData, traitName) => {
    if (trackingData) {
      track(eventName, {
        ...trackingData,
        action: properties.action.close,
        contentType: properties.contentType.trait,
        element: traitName,
      });
    }
    setState({ toggledLetter: undefined });
  };

  const { toggledLetter } = state;
  const currentTrait = traits[letterIndices[toggledLetter]];
  const { name: eventName, props: properties } = EVENT.ContentViewed;

  return (
    <React.Fragment>
      <Flex column>
        {traits.map(trait => {
          const letter = discIdToLetterMapping(trait.id);

          return (
            <React.Fragment key={letter}>
              <TraitBox>
                <ToggleButtonComponent
                  title={trait.secondaryName}
                  subtitle={trait.value || "#"}
                  icon={discIconMapping[letter]}
                  onClick={handleClick(letter)}
                  color={discColorMapping[letter]}
                  disabled={disabled}
                  toggled={toggledLetter === letter}
                  onClose={handleClose}
                  trackingData={{
                    ...trackingData,
                    element: trait.secondaryName,
                  }}
                  testId={`disc-trait-${letter}`}
                />
              </TraitBox>
              {toggledLetter === letter && trait.teamDescription && (
                <TeamToggleButtonDetails
                  teamDescription={trait.teamDescription}
                  color={discColorMapping[letter]}
                />
              )}
              {toggledLetter === letter && !trait.teamDescription && (
                <ToggleButtonDetails
                  color={discColorMapping[letter]}
                  details={wrapContent(currentTrait, {
                    color: discColorMapping[letter],
                    border: true,
                  })}
                  onClick={() => handleClose(eventName, properties, trackingData, trait.secondaryName)}
                />
              )}
            </React.Fragment>
          );
        })}
      </Flex>
      {children({ onClick: handleClick, toggledLetter })}
    </React.Fragment>
  );
}

export default DiscTraits;
