import React, { memo } from "react";
import {
  string,
  arrayOf,
  oneOfType,
  func,
  number,
  shape
} from "prop-types";
import castArray from "lodash/fp/castArray";
import get from "lodash/fp/get";
import { publicUserPropType, trackingDataProp } from "../../../lib/graphql/propTypes";
import ResponsiveSvgContainer from "../../ui/ResponsiveSvgContainer";
import { iconProp } from "../../utils/propTypes";
import { DISC_CHART_LAYOUT } from "../Charts/DISC/utils";
import { isTTIUser } from "../Dashboard/utils";
import DiscWheelBackground from "./DiscWheelBackground";
import DiscWheelChart from "./DiscWheelChart";

// Gets the TTI wheel from the user, if passed an array of users it returns undefined
const getTTIDiscWheel = user => isTTIUser(user) && get("scores.disc.tti.wheel", user);

function DiscWheel({
  users, onClick, selectedLetter, avatarSize = 2, discLayout = DISC_CHART_LAYOUT, trackingData,
}) {
  const ttiDiscWheel = getTTIDiscWheel(users);

  if (ttiDiscWheel) {
    return (
      <ResponsiveSvgContainer mt={2}>
        <svg width="100%" height="100%" viewBox="0 0 590 640" xmlns="http://www.w3.org/2000/svg">
          <image height="100%" width="100%" xlinkHref={ttiDiscWheel} />
        </svg>
      </ResponsiveSvgContainer>
    );
  }

  return (
    <ResponsiveSvgContainer m={2}>
      {users && (
        <DiscWheelChart
          users={castArray(users)}
          avatarSize={avatarSize}
          discLayout={discLayout}
          trackingData={trackingData}
        />
      )}
      <DiscWheelBackground onClick={onClick} selectedLetter={selectedLetter} discLayout={discLayout} />
    </ResponsiveSvgContainer>
  );
}

DiscWheel.displayName = "DiscWheel";

DiscWheel.propTypes = {
  users: oneOfType([
    publicUserPropType,
    arrayOf(publicUserPropType),
  ]),
  onClick: func,
  selectedLetter: string,
  avatarSize: number,
  discLayout: arrayOf(shape({
    letter: string.isRequired,
    title: string.isRequired,
    color: string.isRequired,
    icon: iconProp.isRequired,
    order: number.isRequired,
  })),
  trackingData: trackingDataProp,
};

export default memo(DiscWheel);
