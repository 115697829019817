import React from "react";
import { node } from "prop-types";
import { Flex, Box } from ".";

function ResponsiveSvgContainer({ children, ...flexProps }) {
  return (
    <Box position="relative" w={1} pt="100%" column>
      <Flex position="absolute" top="0" bottom="0" left="0" right="0" column {...flexProps}>
        {children}
      </Flex>
    </Box>
  );
}

ResponsiveSvgContainer.propTypes = {
  children: node.isRequired,
};

export default ResponsiveSvgContainer;
