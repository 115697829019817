import React from "react";
import PropsTypes from "prop-types"
import styled from "styled-components";
import {
  Box,
  Grommet,
  Markdown,
  Tab,
  Text,
} from "grommet";
import { ButtonTabs } from "@/cloverleaf-ui/components";
import { themeStyle } from "@/cloverleaf-ui/theme";
import { useLocale } from "@/lib/hooks";

/**
 * Strings that need translation
 * 1. To work together:
 * 2. Do
 * 3. Don't
 */

const Ul = styled.ul`
  margin: 0px;
  padding-left: ${themeStyle.global.edgeSize.medium};
`;

const Li = styled.li`
  margin: 0px;
  padding-left: ${themeStyle.global.edgeSize.xsmall};
`;

const UnorderedList = ({ children, ...props }) => {
  const listItems = React.Children.map(children, (child) => (
    child ? <Li>{child}</Li> : null
  ));

  return (
    <Ul {...props}>
      {listItems}
    </Ul>
  )
}

function TeamToggleButtonDetails({ teamDescription, color }) {
  const { t } = useLocale();

  const { description, do: dos, dont } = teamDescription;

  return (
    <Grommet theme={themeStyle}>
      <Box
        round="xsmall"
        border={{ color }}
        pad="xsmall"
        margin={{ top: "xsmall" }}
        gap="xsmall"
      >
        <Markdown
          components={{
            p: {
              props: {
                margin: {
                  top: "0px",
                  bottom: "xsmall",
                },
                size: "small",
              }
            },
          }}
        >
          {description}
        </Markdown>
        <Text
          size="small"
          weight="bold"
        >
          {t("dash.disc.to-work-together")}:
        </Text>
        <ButtonTabs>
          <Tab title={t("dash.disc.do").toUpperCase()}>
            <UnorderedList>
              {dos.map((item, index) => (
                <Text key={`do-${index}`} size="small">
                  {item}
                </Text>
              ))}
            </UnorderedList>
          </Tab>
          <Tab title={t("dash.disc.dont").toUpperCase()}>
            <UnorderedList>
              {dont.map((item, index) => (
                <Text key={`dont-${index}`} size="small">
                  {item}
                </Text>
              ))}
            </UnorderedList>
          </Tab>
        </ButtonTabs>
      </Box>
    </Grommet>
  );
}

TeamToggleButtonDetails.propTypes = {
  teamDescription: PropsTypes.shape({
    description: PropsTypes.string.isRequired,
    do: PropsTypes.arrayOf(PropsTypes.string).isRequired,
    dont: PropsTypes.arrayOf(PropsTypes.string).isRequired,
  }).isRequired,
  color: PropsTypes.string.isRequired,
};

export { TeamToggleButtonDetails };
