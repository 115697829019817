import React from "react";
import {
  string,
  func,
  number,
  arrayOf,
  shape
} from "prop-types";
import styled from "styled-components";
import { Box, Text } from "grommet";
import { Flex } from "../../ui";
import IconWithBackground from "../../ui/IconWithBackground";
import { convertHexToRGBA } from "../../utils";
import { iconProp } from "../../utils/propTypes";
import { sameAs, LAYOUT } from "../../utils/zIndex";
import { useLocale } from "@/lib/hooks";

const discHoverStyle = props => (`
  cursor: pointer;
  background-color: ${((props.borderColor && !props.filled) ? convertHexToRGBA(props.borderColor, 0.2) : "")};
  transition: none;
`);

const DiscCorner = styled.div`
  flex: auto;
  transition: background-color 200ms linear;
  border-radius: ${props => (props.borderRadius ? props.borderRadius : "")};
  border: 1px solid ${props => (props.borderColor ? props.borderColor : "")};
  background-color: ${props => (props.filled ? `${props.borderColor}` : "")};
  z-index: ${sameAs(LAYOUT)};

  &:first-child {
    margin-right: 15px;
  }

  &:hover {
    ${props => (props.onClick && discHoverStyle(props))}
  }
`;

function DiscWheelBackground ({
  discLayout,
  onClick = undefined,
  selectedLetter = undefined,
}) {
  const { t } = useLocale()
  const renderDiscCorners = ({ topLeft, topRight, bottomRight, bottomLeft }) => {
    return (
      <React.Fragment>
        <Flex mb="15px" auto wrap>
          <DiscCorner
            data-testid="top-left"
            filled={selectedLetter === topLeft.letter}
            borderColor={topLeft.color}
            borderRadius="99% 0 0 0"
            onClick={onClick && onClick(topLeft.letter)}
          />
          <DiscCorner
            data-testid="top-right"
            filled={selectedLetter === topRight.letter}
            borderColor={topRight.color}
            borderRadius="0 99% 0 0"
            onClick={onClick && onClick(topRight.letter)}
          />
        </Flex>
        <Flex auto wrap>
          <DiscCorner
            data-testid="bottom-left"
            filled={selectedLetter === bottomLeft.letter}
            borderColor={bottomLeft.color}
            borderRadius="0 0 0 99%"
            onClick={onClick && onClick(bottomLeft.letter)}
          />
          <DiscCorner
            data-testid="bottom-right"
            filled={selectedLetter === bottomRight.letter}
            borderColor={bottomRight.color}
            borderRadius="0 0 99% 0"
            onClick={onClick && onClick(bottomRight.letter)}
          />
        </Flex>
      </React.Fragment>
    );
  }

  // Deconstruct the disc layout to place the quadrants accordingly
  const [topLeft, topRight, bottomRight, bottomLeft] = discLayout;

  return (
    <React.Fragment>
      <Flex position="absolute" top="0" w={1} justify="space-between">
        <Box gap="4px" align="center">
          <Text size="10px" color={topLeft.color}>
            {t(`disc.titles.${topLeft.title}`)}
          </Text>
          <IconWithBackground
            icon={topLeft.icon}
            color={topLeft.color}
          />
        </Box>
        <Box gap="4px" align="center">
          <Text size="10px" color={topRight.color}>
            {t(`disc.titles.${topRight.title}`)}
          </Text>
          <IconWithBackground
            icon={topRight.icon}
            color={topRight.color}
          />
        </Box>
      </Flex>
      <Flex position="absolute" bottom="0" w={1} justify="space-between">
        <Box gap="4px" align="center" margin={{ left: "-16px" }}>
          <IconWithBackground
            icon={bottomLeft.icon}
            color={bottomLeft.color}
          />

          <Text size="10px" color={bottomLeft.color}>
            {t(`disc.titles.${bottomLeft.title}`)}
          </Text>

        </Box>
        <Box gap="4px" align="center">
          <IconWithBackground
            icon={bottomRight.icon}
            color={bottomRight.color}
          />
          <Box>
            <Text size="10px" color={bottomRight.color}>
              {t(`disc.titles.${bottomRight.title}`)}
            </Text>
          </Box>
        </Box>
      </Flex>
      {renderDiscCorners({ topLeft, topRight, bottomRight, bottomLeft })}
    </React.Fragment>
  );
}

DiscWheelBackground.propTypes = {
  onClick: func,
  selectedLetter: string,
  discLayout: arrayOf(shape({
    letter: string.isRequired,
    title: string.isRequired,
    color: string.isRequired,
    icon: iconProp.isRequired,
    order: number.isRequired,
  })).isRequired,
}

export default DiscWheelBackground;
