import React from "react";
import {
  number,
  string,
  shape
} from "prop-types";
import { publicUserPropType } from "../../lib/graphql/propTypes";
import { Avatar, HorizontalEntity } from "../ui";

function HorizontalUser({
  user, size = 2, label = undefined, subLabel = undefined, labelProps = {}, ...avatarProps
}) {
  return (
    <HorizontalEntity
      entity={(
        <Avatar
          size={size}
          expandable={false}
          user={user}
          {...avatarProps}
        />
      )}
      label={label || user.fullName}
      subLabel={subLabel}
      labelProps={{ large: size > 2, ...labelProps }}
    />
  );
}

HorizontalUser.propTypes = {
  user: publicUserPropType.isRequired,
  size: number,
  label: string,
  labelProps: shape({}),
  subLabel: string,
};

export default HorizontalUser;
