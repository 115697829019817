import React from "react";
import {
  arrayOf,
  number,
  shape,
  string
} from "prop-types";
import {
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { publicUserPropType, trackingDataProp } from "../../../lib/graphql/propTypes";
import { Flex } from "../../ui";
import { ASSESSMENT, EVENT } from "../../utils/constants";
import { iconProp } from "../../utils/propTypes";
import { above, LAYOUT } from "../../utils/zIndex";
import { formatDiscCoordinatesAndUsers } from "../Charts/DISC/utils";
import Shape from "../Charts/Shape";
import DiscWheelTooltip from "./DiscWheelTooltip";

class DiscWheelChart extends React.PureComponent {
  static propTypes = {
    users: arrayOf(publicUserPropType).isRequired,
    avatarSize: number,
    discLayout: arrayOf(shape({
      letter: string.isRequired,
      title: string.isRequired,
      color: string.isRequired,
      icon: iconProp.isRequired,
      order: number.isRequired,
    })).isRequired,
    trackingData: trackingDataProp,
  }

  static defaultProps = {
    avatarSize: 2,
    trackingData: undefined,
  }

  renderTooltip = (props) => {
    if (props.active && props.payload?.length > 0) {
      const users = props.payload[0]?.payload?.users || {};

      return <DiscWheelTooltip users={users} />;
    }

    return null;
  }

  render() {
    const { users, avatarSize, discLayout, trackingData } = this.props;

    const usersWithShape = users.map(user => ({ ...user, shape: user.shape || "avatar" }));

    const coordinatesWithUsers = formatDiscCoordinatesAndUsers(usersWithShape, discLayout);

    const { props: properties } = EVENT.ContentViewed;

    return (
      <Flex position="absolute" w={1} h={1}>
        <ResponsiveContainer width="100%" height="100%">
          <ScatterChart style={{ zIndex: above(LAYOUT), pointerEvents: "none" }}>
            <XAxis dataKey="x" type="number" hide ticks={[-100, 100]} />
            <YAxis dataKey="y" type="number" hide ticks={[-100, 100]} />
            <Tooltip content={this.renderTooltip} cursor={false} isAnimationActive={false} />
            <Scatter
              id="disc-scores"
              data={coordinatesWithUsers}
              isAnimationActive={false}
              shape={({ key, ...props }) => (
                <Shape
                  key={key}
                  avatarSize={avatarSize}
                  assessment={ASSESSMENT.DISC}
                  trackingData={{
                    ...trackingData,
                    action: properties.action.open,
                    element: properties.element.selfDiscWheel,
                  }}
                  {...props}
                />
              )}
            />
          </ScatterChart>
        </ResponsiveContainer>
      </Flex>
    );
  }
}

export default DiscWheelChart;
