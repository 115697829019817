import React from "react";
import {
  arrayOf,
  bool,
  number,
  string
} from "prop-types";
import isEmpty from "lodash/fp/isEmpty";
import { Dot } from "recharts";
import { publicUserPropType, trackingDataProp } from "../../../lib/graphql/propTypes";
import { TextLabel } from "../../ui";
import AvatarWithUserStatsCard from "../../ui/AvatarWithUserStatsCard";
import CircleContainer from "../../ui/CircleContainer";
import { getCircleImgSize, getCircleImgSizePx } from "../../utils";
import {
  DEFAULT_FONT,
  GREEN,
  GREY_OPAQUE,
  WHITE
} from "../../utils/theme";

class Shape extends React.PureComponent {
  static propTypes = {
    cx: number.isRequired,
    cy: number.isRequired,
    users: arrayOf(publicUserPropType).isRequired,
    border: bool,
    avatarSize: number,
    assessment: string,
    trackingData: trackingDataProp,
  }

  static defaultProps = {
    border: false,
    avatarSize: 2,
    assessment: undefined,
    trackingData: undefined,
  }

  getShapeDetails = () => {
    const {
      users, avatarSize, border, assessment, trackingData,
    } = this.props;

    if (isEmpty(users)) return null;

    if (users[0] && users[0].shape === "avatar") {
      const multiple = users.length > 1;
      const size = multiple ? avatarSize + .75 : avatarSize;
      const elementSize = getCircleImgSize(size);
      const elementSizeInPx = getCircleImgSizePx(size);
      const elementProps = {
        user: users[0],
        size,
        border,
        assessment,
        trackingData: { ...trackingData, targetUserId: users[0]?.id },
        noAnimate: true,
      };

      const Element = users[0].AvatarComponent || AvatarWithUserStatsCard;

      return ({
        size,
        elementSize,
        elementSizeInPx,
        element: (<Element {...elementProps} style={{ position: "fixed" }} />),
      });
    }

    return null;
  }

  renderDuplicate = (users, size, border) => (
    <CircleContainer background="grey" size={size} border={border} style={{ position: "fixed" }}>
      <TextLabel thin uppercase color={WHITE} font={DEFAULT_FONT}>
        {users.length}
      </TextLabel>
    </CircleContainer>
  )

  render() {
    const {
      cx, cy, users, border, avatarSize,
    } = this.props;
    const multiple = users.length > 1;

    const {
      size,
      elementSize,
      elementSizeInPx,
      element,
    } = this.getShapeDetails() || {};

    if (!size && !elementSize && !elementSizeInPx && !element) return null;

    return (
      <switch>
        <foreignObject
          role="img"
          aria-label={multiple ? "Multiple users" : element?.props?.user?.fullName}
          x={cx - (elementSize / 2)}
          y={cy - (elementSize / 2)}
          width={elementSizeInPx}
          height={elementSizeInPx}
          overflow="visible"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        >
          {multiple
            ? this.renderDuplicate(users, size, border)
            : element
          }
        </foreignObject>
        <Dot
          cx={cx}
          cy={cy}
          r={avatarSize * 5}
          fill={GREY_OPAQUE}
          stroke={GREEN}
          strokeWidth={border ? 2 : 0}
          style={{ pointerEvents: "all" }}
        />
      </switch>
    );
  }
}

export default Shape;
